import React from 'react';
import AwesomeSlider from 'react-awesome-slider';
import 'react-awesome-slider/dist/styles.css';
import withAutoplay from 'react-awesome-slider/dist/autoplay';

// components and helpers
import dataMeshImage from '../../assets/images/cryptographic_data_mesh_ad.png';
import cancerAiImage from '../../assets/images/cancer_trials_ai_ad.png';

// mui
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const AutoplaySlider = withAutoplay(AwesomeSlider);
const ProductCard: React.FC = () => {
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('sm'));
    const products = [
        {
            title: 'Cryptographic Data Mesh',
            image: dataMeshImage,
            link: 'https://cryptographicdatamesh.com/',
            size: matches ? 'cover' : 'contain',
            description:
                'A comprehensive, real-time, and interactive data visualization tool that provides a comprehensive view of cancer clinical trials and their progress.',
        },
        {
            title: 'Cancer Trials Explorer and AI Companion',
            image: cancerAiImage,
            link: 'https://cancertrials.acoer.com/',
            size: matches ? 'cover' : 'contain',
            description:
                'A comprehensive, real-time, and interactive data visualization tool that provides a comprehensive view of cancer clinical trials and their progress.',
        },
    ];

    return (
        <Grid container spacing={1}>
            <Grid item xs={12} sx={{ height: 270 }}>
                <AutoplaySlider
                    play={true}
                    cancelOnInteraction={false} // should stop playing on user interaction
                    interval={6000}
                    organicArrows={false}
                    buttons={false}
                    bullets={false}
                >
                    {products.map((product, index) => (
                        <Paper
                            key={index}
                            elevation={0}
                            onClick={() => window.open(product.link, '_blank')}
                            style={{
                                padding: 15,
                                borderRadius: 15,
                                height: 270,
                                background: '#0d0d0d',
                                backgroundImage: `url(${product.image})`,
                                backgroundSize: product.size,
                                backgroundPosition: 'center',
                                backgroundRepeat: 'no-repeat',
                                cursor: 'pointer',
                            }}
                        />
                    ))}
                </AutoplaySlider>
            </Grid>
        </Grid>
    );
};

export default ProductCard;

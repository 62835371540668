import React, { useContext, useEffect, useState } from 'react';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';

// component and helper imports
import { AppContext } from '../../containers/AppProviderWrapper';
import { createDetailsList } from './helpers/createDetailsList';
import SearchTokenSkeletonLoading from '../Shared/LoadingSkeletons/SearchTokenSkeletonLoading';
import Breadcrumbs from '../Shared/Breadcrumbs';
import utils from '../../common/utils/utils';
import { nodeAccounts } from './helpers/itemTypes';
import SectionDetail from './SectionDetail';
import { searchActions } from './SearchActions';
import SearchDetailTableSection from './SearchDetailTableSection';
import { INodeProps } from '../../common/model/ExplorerModels';

// mui
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import List from '@mui/material/List';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const SearchDetail: React.FC = () => {
    const { currentPath, hederaNodeData } = useContext(AppContext);
    const dispatch = useDispatch();
    const [copyShow, setCopyShow] = useState<string>('');
    const [nodeAccount, setNodeAccount] = useState<boolean>(false);
    const [nodeData, setNodeData] = useState<INodeProps>({});
    const [searchValue, setSearchValue] = useState<string | undefined>();
    const [searchType, setSearchType] = useState<string | undefined>();
    const [detail, setDetail] = useState<any>();
    const [nftDetail, setNftDetail] = useState<any>();
    const [balanceDetail, setBalanceDetail] = useState<any>();
    const [accountTxDetails, setAccountTxDetails] = useState<any>();
    const accountDetails = useSelector((state: RootStateOrAny) => state.search.accountDetails);
    const accountTransactionDetails = useSelector((state: RootStateOrAny) => state.search.accountTransactionDetails);
    const accountTransactionDetailsLink = useSelector(
        (state: RootStateOrAny) => state.search.accountTransactionDetailsLink,
    );
    const accountTransactionDetailsLoading = useSelector(
        (state: RootStateOrAny) => state.search.accountTransactionDetailsLoading,
    );
    const accountTokenDetails = useSelector((state: RootStateOrAny) => state.search.accountTokenDetails);
    const tokenDetails = useSelector((state: RootStateOrAny) => state.search.tokenDetails);
    const balancesDetails = useSelector((state: RootStateOrAny) => state.search.balancesDetails);
    const balancesDetailsLoading = useSelector((state: RootStateOrAny) => state.search.balancesDetailsLoading);
    const nftsDetails = useSelector((state: RootStateOrAny) => state.search.nftsDetails);
    const nftsDetailsLoading = useSelector((state: RootStateOrAny) => state.search.nftsDetailsLoading);
    const transactionDetails = useSelector((state: RootStateOrAny) => state.search.transactionDetails);
    const txTopicMessage = useSelector((state: RootStateOrAny) => state.search.txTopicMessage);
    const scheduleDetails = useSelector((state: RootStateOrAny) => state.search.scheduleDetails);
    const contractDetails = useSelector((state: RootStateOrAny) => state.search.contractDetails);
    const topicDetails = useSelector((state: RootStateOrAny) => state.search.topicDetails);
    const detailsLoading = useSelector((state: RootStateOrAny) => state.search.detailsLoading);
    const nodeDetails = useSelector((state: RootStateOrAny) => state.search.nodeDetails);
    const nodeDetailLoading = useSelector((state: RootStateOrAny) => state.search.nodeDetailLoading);
    const searchDetailsDataError = useSelector((state: RootStateOrAny) => state.search.searchDetailsDataError);
    const API_ROOT = process.env.REACT_APP_HEDERA_PROXY;
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('sm'));

    useEffect(() => {
        if (searchType === 'account' && accountDetails && hederaNodeData) {
            setDetail(accountDetails);
            if (nodeAccounts.includes(searchValue as string)) {
                const nodeIdMatch = hederaNodeData && hederaNodeData.filter((x: any) => x.accountId === searchValue);
                setNodeAccount(true);
                setNodeData(nodeIdMatch[0]);
                if (nodeIdMatch && nodeIdMatch.length) {
                    dispatch(searchActions?.getNodeDetails(nodeIdMatch[0].id));
                }
            }
        }
    }, [searchType, accountDetails, hederaNodeData, searchValue]);

    useEffect(() => {
        if (searchType === 'token' && tokenDetails) {
            setDetail(tokenDetails);
        }
    }, [searchType, tokenDetails]);

    useEffect(() => {
        if (searchType === 'token' && tokenDetails) {
            setDetail(tokenDetails);
        }
    }, [searchType, tokenDetails]);

    useEffect(() => {
        if (searchType === 'transaction' && transactionDetails) {
            setDetail(transactionDetails.transactions[0]);
            dispatch(searchActions?.getTxTopicMessage(transactionDetails.transactions[0].consensus_timestamp));
        }
    }, [searchType, transactionDetails]);

    useEffect(() => {
        if (searchType === 'schedule' && scheduleDetails) {
            setDetail(scheduleDetails);
        }
    }, [searchType, scheduleDetails]);

    useEffect(() => {
        if (searchType === 'contract' && contractDetails) {
            setDetail(contractDetails);
        }
    }, [searchType, contractDetails]);

    useEffect(() => {
        if (searchType === 'topic' && topicDetails) {
            setDetail(topicDetails);
        }
    }, [searchType, topicDetails]);

    useEffect(() => {
        if (searchType === 'token' && balancesDetails) {
            setBalanceDetail(balancesDetails);
        }
    }, [searchType, balancesDetails]);

    useEffect(() => {
        if (searchType === 'token' && nftsDetails) {
            setNftDetail(nftsDetails);
        }
    }, [searchType, nftsDetails]);

    useEffect(() => {
        if (searchType === 'account' && accountTransactionDetails) {
            setAccountTxDetails(accountTransactionDetails);
        }
    }, [searchType, accountTransactionDetails]);

    useEffect(() => {
        if (searchType === 'transaction' && txTopicMessage) {
            setDetail({
                ...detail,
                topic_message: txTopicMessage,
            });
        }
    }, [searchType, detail, txTopicMessage]);

    useEffect(() => {
        return () => {
            clearLocal();
        };
    }, []);

    useEffect(() => {
        const value = window.location.pathname.split('/').pop();
        let type = window.location.pathname.split('/')[2];
        if (type === 'transaction-details') {
            type = 'transaction';
        }
        setSearchValue(value);
        setSearchType(type);
        clearLocal();
    }, [currentPath]);

    useEffect(() => {
        if (searchValue != null) {
            setupFetchData(searchValue, 10);
        }
        return () => {
            clearLocal();
        };
    }, [searchValue, searchType]);

    const setupFetchData = (searchValue: string, pageLimit: number, type?: string, nextLink?: string) => {
        let value = searchValue;
        if (searchValue.includes('@')) {
            value = utils.txToApi(searchValue);
        }
        let url;
        if (searchType === 'topic') {
            url = `${API_ROOT}/${searchType}s/${value}/messages`;
            dispatch(searchActions?.getSearchDetails(url, pageLimit, 'desc', searchType, nextLink));
        } else if (searchType === 'token') {
            if (type === 'balances') {
                const balanceUrl = `${API_ROOT}/${searchType}s/${value}/balances`;
                dispatch(searchActions?.getSearchDetails(balanceUrl, pageLimit, 'desc', type, nextLink));
            } else if (type === 'nfts') {
                const nftUrl = `${API_ROOT}/${searchType}s/${value}/nfts`;
                dispatch(searchActions?.getSearchDetails(nftUrl, pageLimit, 'desc', type, nextLink));
            } else {
                url = `${API_ROOT}/${searchType}s/${value}`;
                const nftUrl = `${API_ROOT}/${searchType}s/${value}/nfts`;
                const balanceUrl = `${API_ROOT}/${searchType}s/${value}/balances`;
                dispatch(searchActions?.getSearchDetails(balanceUrl, pageLimit, 'desc', 'balances', nextLink));
                dispatch(searchActions?.getSearchDetails(nftUrl, pageLimit, 'desc', 'nfts', nextLink));
                dispatch(searchActions?.getSearchDetails(url, null, null, 'token', nextLink));
            }
        } else if (searchType === 'transaction') {
            url = `${API_ROOT}/${searchType}s/${value}`;
            dispatch(searchActions?.getSearchDetails(url, null, null, searchType, nextLink));
        } else if (type === 'accountTransaction') {
            url = `${API_ROOT}/${searchType}s/${value}`;
            dispatch(searchActions?.getSearchDetails(url, pageLimit, 'desc', type, nextLink));
        } else {
            url = `${API_ROOT}/${searchType}s/${value}`;
            dispatch(searchActions?.getSearchDetails(url, pageLimit, 'desc', searchType, nextLink));
        }
    };

    const clearLocal = () => {
        setDetail(null);
        setNftDetail(null);
        setBalanceDetail(null);
        setNodeAccount(false);
        setNodeData({});
    };

    const createSectionDetails = (data: any, type: string, typeDetail?: string | number) => {
        let newData = { ...data };
        if (searchType === 'contract' && data.timestamp) {
            newData = { ...data };
            newData['valid_from'] = newData.timestamp.from;
            newData['valid_to'] = newData.timestamp.to;
        }
        if (searchType === 'account' && data.staking_period) {
            newData = { ...data };
            newData['staking_period_from'] = newData.staking_period.from;
            newData['staking_period_to'] = newData.staking_period.to;
        }
        if (searchType === 'account' && data.timestamp && !data.staking_period) {
            newData = { ...data };
            newData['valid_from'] = newData.timestamp.from;
            newData['valid_to'] = newData.timestamp.to;
        }

        const detailEntries = Object.entries(newData);
        return <List>{createDetailsList(detailEntries, copyShow, setCopyShow, type, typeDetail)}</List>;
    };

    const createTransferDetails = (type: string, data: any) => {
        const transferFrom: any = [];
        const transferTo: any = [];

        if (type === 'nftTransfers') {
            const transferData = [...data];
            transferData.map((value: any, i: number) => {
                if (value.receiver_account_id) {
                    delete value.sender_account_id;
                    transferTo.push(value);
                } else {
                    delete value.receiver_account_id;
                    transferFrom.push(value);
                }
            });
        } else {
            data.map((value: any, i: number) => {
                if (Math.sign(value.amount) === 1) {
                    transferTo.push(value);
                } else {
                    transferFrom.push(value);
                }
            });
        }

        if (transferFrom.length === 0 && (type === 'token_transfers' || type === 'nftTransfers')) {
            data.map((value: any, i: number) => {
                transferFrom.push({
                    account: 'MINT',
                    token_id: value.token_id,
                    amount: `-${value.amount}`,
                });
            });
        } else if (transferTo.length === 0 && (type === 'token_transfers' || type === 'nftTransfers')) {
            data.map((value: any, i: number) => {
                transferTo.push({
                    account: 'BURN',
                    token_id: value.token_id,
                    amount: Math.abs(value.amount),
                });
            });
        }

        if (type === 'nftTransfers') {
            return (
                <div>
                    {transferFrom.map((itemFrom: any, i: number) => {
                        return (
                            <Grid container alignItems={'center'} key={`transfer-${i}`}>
                                <Grid item xs={12} md={5}>
                                    <List key={`transferFrom-${i}`}>
                                        {createDetailsList(Object.entries(itemFrom), copyShow, setCopyShow, type)}
                                    </List>
                                </Grid>
                                <Grid item xs={2}>
                                    <Grid
                                        container
                                        justifyContent={'center'}
                                        direction={'column'}
                                        alignItems={'center'}
                                    >
                                        <Grid item>
                                            <ArrowForwardIcon style={{ textAlign: 'center' }} color={'primary'} />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} md={5}>
                                    <List key={`transferTo-${i}`}>
                                        {createDetailsList(Object.entries(transferTo[i]), copyShow, setCopyShow, type)}
                                    </List>
                                </Grid>
                            </Grid>
                        );
                    })}
                </div>
            );
        } else {
            return (
                <Grid container alignItems={'center'}>
                    <Grid item xs={12} md={5}>
                        {transferFrom.map((item: any, i: number) => {
                            return (
                                <List key={`transferFrom-${i}`}>
                                    {createDetailsList(Object.entries(item), copyShow, setCopyShow, type)}
                                </List>
                            );
                        })}
                    </Grid>
                    <Grid item xs={2}>
                        <Grid container justifyContent={'center'} direction={'column'} alignItems={'center'}>
                            <Grid item>
                                <ArrowForwardIcon style={{ textAlign: 'center' }} color={'primary'} />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={5}>
                        {transferTo.map((item: any, i: number) => {
                            return (
                                <List key={`transferTo-${i}`}>
                                    {createDetailsList(Object.entries(item), copyShow, setCopyShow, type)}
                                </List>
                            );
                        })}
                    </Grid>
                </Grid>
            );
        }
    };

    return (
        <Grid container justifyContent={'center'} spacing={2}>
            <Grid item xs={10}>
                <Breadcrumbs />
            </Grid>
            {(detailsLoading || !detail) && !searchDetailsDataError && searchType !== 'topic' ? (
                <SearchTokenSkeletonLoading />
            ) : (
                <Grid item xs={11} md={10}>
                    {searchDetailsDataError ? (
                        <Paper elevation={0} style={{ padding: matches ? 36 : 15, borderRadius: 15 }}>
                            <Typography
                                variant={'h6'}
                                style={{ fontWeight: 'bold', fontFamily: 'Inter, sans-serif', textAlign: 'center' }}
                                color={'secondary'}
                            >
                                There was no match for {searchType} ID <i>&quot;{searchValue}&quot;</i>.
                            </Typography>
                            <Typography
                                variant={'h6'}
                                style={{ fontWeight: 'bold', fontFamily: 'Inter, sans-serif', textAlign: 'center' }}
                                color={'secondary'}
                            >
                                Please check your {searchType} ID and try again.
                            </Typography>
                        </Paper>
                    ) : (
                        <Grid container justifyContent={'center'} spacing={2}>
                            <Grid item xs={12}>
                                <Grid container justifyContent={'center'} spacing={2} alignItems={'center'}>
                                    <Grid item xs={12}>
                                        <Divider color={theme.palette.secondary.main} sx={{ height: 2 }} />
                                    </Grid>
                                    <Grid item>
                                        <Typography
                                            variant={matches ? 'h5' : 'body1'}
                                            style={{
                                                fontWeight: 'bold',
                                                fontFamily: 'Inter, sans-serif',
                                                textAlign: 'center',
                                            }}
                                            color={'textPrimary'}
                                        >
                                            {searchType && utils.toTitleText(searchType)}{' '}
                                            {searchValue && searchType === 'transaction'
                                                ? utils.txToReadable(searchValue)
                                                : searchValue}{' '}
                                            {nodeAccount && `- ${nodeData.organization} (Node ${nodeData.id})`}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Divider color={theme.palette.secondary.main} sx={{ height: 2 }} />
                                    </Grid>
                                </Grid>
                            </Grid>
                            {searchType === 'account' && nodeDetails && (
                                <Grid item xs={12}>
                                    <Paper elevation={0} style={{ padding: matches ? 36 : 15, borderRadius: 15 }}>
                                        <Typography
                                            variant={'h6'}
                                            style={{ fontWeight: 'bold', fontFamily: 'Inter, sans-serif' }}
                                            color={'secondary'}
                                        >
                                            Network Node Details
                                        </Typography>
                                        {nodeDetails && createSectionDetails(nodeDetails, searchType as string)}
                                    </Paper>
                                </Grid>
                            )}
                            {searchType === 'account' && nodeDetails && nodeDetails.service_endpoints.length > 0 && (
                                <Grid item xs={12}>
                                    <SearchDetailTableSection
                                        loading={nodeDetailLoading}
                                        searchValue={searchValue as string}
                                        setupFetchData={setupFetchData}
                                        clearLocal={clearLocal}
                                        type={'nodeServiceEndpoints'}
                                        data={nodeDetails.service_endpoints}
                                        title={'Service Endpoints'}
                                        links={null}
                                    />
                                </Grid>
                            )}
                            <Grid item xs={12}>
                                {searchType !== 'topic' && (
                                    <Paper elevation={0} style={{ padding: matches ? 36 : 15, borderRadius: 15 }}>
                                        <Typography
                                            variant={'h6'}
                                            style={{
                                                fontWeight: 'bold',
                                                fontFamily: 'Inter, sans-serif',
                                                color: theme.palette.mode === 'light' ? '#0d0d0d' : '#FFDB58',
                                            }}
                                        >
                                            {searchType && utils.toTitleText(searchType)} Details
                                        </Typography>
                                        {detail &&
                                            createSectionDetails(
                                                detail,
                                                searchType as string,
                                                (searchType === 'transaction' && detail.name) ||
                                                    (searchType === 'token' && detail.decimals),
                                            )}
                                    </Paper>
                                )}
                            </Grid>
                            {detail && detail.token_transfers && detail.token_transfers.length > 0 && (
                                <Grid item xs={12}>
                                    <SectionDetail
                                        title={'Token Transfers'}
                                        detailType={createTransferDetails('token_transfers', detail.token_transfers)}
                                    />
                                </Grid>
                            )}
                            {detail && detail.transfers && detail.transfers.length > 0 && (
                                <Grid item xs={12}>
                                    <SectionDetail
                                        title={'Hbar & Fee Transfers'}
                                        detailType={createTransferDetails('transfers', detail.transfers)}
                                    />
                                </Grid>
                            )}
                            {detail && detail.nft_transfers && detail.nft_transfers.length > 0 && (
                                <Grid item xs={12}>
                                    <SectionDetail
                                        title={'NFT Transfers'}
                                        detailType={createTransferDetails('nftTransfers', detail.nft_transfers)}
                                    />
                                </Grid>
                            )}
                            {searchType === 'account' && accountTxDetails && (
                                <Grid item xs={12} md={searchType === 'account' ? 8 : 12}>
                                    <SearchDetailTableSection
                                        loading={accountTransactionDetailsLoading}
                                        searchValue={searchValue as string}
                                        setupFetchData={setupFetchData}
                                        clearLocal={clearLocal}
                                        type={'accountTransaction'}
                                        data={accountTxDetails}
                                        title={'Recent Transactions'}
                                        links={accountTransactionDetailsLink}
                                    />
                                </Grid>
                            )}
                            {((searchType !== 'account' &&
                                detail &&
                                detail.balance &&
                                detail.balance.tokens &&
                                detail.balance.tokens.length > 0) ||
                                (searchType === 'account' && accountTokenDetails)) && (
                                <Grid item xs={12} md={searchType === 'account' ? 4 : 12}>
                                    <SearchDetailTableSection
                                        loading={detailsLoading}
                                        searchValue={searchValue as string}
                                        setupFetchData={setupFetchData}
                                        clearLocal={clearLocal}
                                        type={'token'}
                                        data={searchType === 'account' ? accountTokenDetails : detail.balance.tokens}
                                        title={'Tokens'}
                                        links={searchType !== 'account' && detail.balance.links}
                                    />
                                </Grid>
                            )}
                            {detail && detail.signatures && detail.signatures.length > 0 && (
                                <Grid item xs={12}>
                                    <SearchDetailTableSection
                                        loading={detailsLoading}
                                        searchValue={searchValue as string}
                                        setupFetchData={setupFetchData}
                                        clearLocal={clearLocal}
                                        type={'signature'}
                                        data={detail.signatures}
                                        title={'Signatures'}
                                        links={detail.links}
                                    />
                                </Grid>
                            )}
                            {detail && detail.messages && detail.messages.length > 0 && (
                                <Grid item xs={12}>
                                    <SearchDetailTableSection
                                        loading={detailsLoading}
                                        searchValue={searchValue as string}
                                        setupFetchData={setupFetchData}
                                        clearLocal={clearLocal}
                                        type={'messages'}
                                        data={detail.messages}
                                        title={'Topic Messages'}
                                        links={detail.links}
                                    />
                                </Grid>
                            )}
                            {nftDetail &&
                                nftDetail.nfts &&
                                nftDetail.nfts.length > 0 &&
                                detail.type === 'NON_FUNGIBLE_UNIQUE' && (
                                    <Grid item xs={12}>
                                        <SearchDetailTableSection
                                            loading={nftsDetailsLoading}
                                            searchValue={searchValue as string}
                                            setupFetchData={setupFetchData}
                                            clearLocal={clearLocal}
                                            type={'nfts'}
                                            data={nftDetail.nfts}
                                            title={'NFT Holders'}
                                            links={nftDetail.links}
                                        />
                                    </Grid>
                                )}
                            {balanceDetail && balanceDetail.balances && balanceDetail.balances.length > 0 && (
                                <Grid item xs={12}>
                                    <SearchDetailTableSection
                                        loading={balancesDetailsLoading}
                                        searchValue={searchValue as string}
                                        setupFetchData={setupFetchData}
                                        clearLocal={clearLocal}
                                        type={'balances'}
                                        data={balanceDetail.balances}
                                        title={'Balances'}
                                        links={balanceDetail.links}
                                    />
                                </Grid>
                            )}
                        </Grid>
                    )}
                </Grid>
            )}
        </Grid>
    );
};

export default SearchDetail;

import { PaletteMode } from '@mui/material';

//adds mui theme
export const getDesignTokens = (mode: PaletteMode) => ({
    palette: {
        mode,
        ...(mode === 'light'
            ? {
                  // palette values for light mode
                  primary: {
                      main: '#FFDB58',
                  },
                  secondary: {
                      main: '#FFDB58',
                  },
                  background: {
                      default: '#F0F0F0',
                      paper: '#FFFFFF',
                  },
                  text: {
                      primary: '#333750',
                      secondary: '#999ba8',
                  },
              }
            : {
                  // palette values for dark mode
                  primary: {
                      main: '#FFDB58',
                  },
                  secondary: {
                      main: '#FFDB58',
                  },
                  background: {
                      default: '#1C1C1C',
                      paper: '#0D0D0D',
                  },
                  text: {
                      primary: '#FFFFFF',
                      secondary: '#999ba8',
                  },
              }),
    },
    typography: {
        fontFamily: 'Inter, sans-serif',
    },
    components: {
        MuiCssBaseline: {
            styleOverrides: `
                            @font-face {
                              font-family: 'Inter';
                              font-style: normal;
                              font-display: swap;
                              src: local('Inter'), local('Inter-Regular'), url(./assets/fonts/Inter/Inter-Regular.ttf) format('ttf');
                              unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
                            }
                          `,
        },
    },
});

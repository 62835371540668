import { ActionCreatorsMapObject, Dispatch, ActionCreator } from 'redux';
import { ThunkAction } from 'redux-thunk';
import axios from 'axios';
import { IExplorerState, IExplorerAction } from '../../common/model/ExplorerModels';

export const types = {
    GET_SEARCH_DETAILS_REQUEST: 'GET_SEARCH_DETAILS_REQUEST',
    GET_SEARCH_DETAILS_SUCCESS: 'GET_SEARCH_DETAILS_SUCCESS',
    GET_SEARCH_DETAILS_FAILURE: 'GET_SEARCH_DETAILS_FAILURE',
    GET_SEARCH_DETAILS_TRANSACTIONS_REQUEST: 'GET_SEARCH_DETAILS_TRANSACTIONS_REQUEST',
    GET_SEARCH_DETAILS_TRANSACTIONS_SUCCESS: 'GET_SEARCH_DETAILS_TRANSACTIONS_SUCCESS',
    GET_SEARCH_DETAILS_TOKENS_SUCCESS: 'GET_SEARCH_DETAILS_TOKENS_SUCCESS',
    GET_TOKEN_DETAILS_REQUEST: 'GET_TOKEN_DETAILS_REQUEST',
    GET_TOKEN_DETAILS_SUCCESS: 'GET_TOKEN_DETAILS_SUCCESS',
    GET_TOKEN_DETAILS_FAILURE: 'GET_TOKEN_DETAILS_FAILURE',
    GET_NODE_DETAILS_REQUEST: 'GET_NODE_DETAILS_REQUEST',
    GET_NODE_DETAILS_SUCCESS: 'GET_NODE_DETAILS_SUCCESS',
    GET_NODE_DETAILS_FAILURE: 'GET_NODE_DETAILS_FAILURE',
    GET_TX_TOPIC_MESSAGE_REQUEST: 'GET_TX_TOPIC_MESSAGE_REQUEST',
    GET_TX_TOPIC_MESSAGE_SUCCESS: 'GET_TX_TOPIC_MESSAGE_SUCCESS',
    GET_TX_TOPIC_MESSAGE_FAILURE: 'GET_TX_TOPIC_MESSAGE_FAILURE',
    RESET_SEARCH_DETAILS: 'RESET_SEARCH_DETAILS',
};

const API_ROOT = process.env.REACT_APP_HEDERA_PROXY;
const getSearchDetails: ActionCreator<ThunkAction<Promise<any>, IExplorerState, null, IExplorerAction>> =
    (searchUrl: string, limit: number, order: string, searchType: string, nextUrl?: string) =>
    async (dispatch: Dispatch) => {
        if (searchType === 'accountTransaction') {
            dispatch({
                type: types.GET_SEARCH_DETAILS_TRANSACTIONS_REQUEST,
                payload: {
                    type: searchType,
                },
            });
        } else {
            dispatch({
                type: types.GET_SEARCH_DETAILS_REQUEST,
                payload: {
                    type: searchType,
                },
            });
        }
        let url = searchUrl;

        if (limit !== null && order !== null) {
            if (nextUrl && searchType !== 'token') {
                url = `${searchUrl}?limit=${limit}&order=${order}${nextUrl}`;
            } else if (searchType !== 'token') {
                url = `${searchUrl}?limit=${limit}&order=${order}`;
            }
        }

        try {
            const response = await axios({
                method: 'GET',
                url,
            });

            const resData = response.data;
            if (searchType === 'account') {
                dispatch({
                    type: types.GET_SEARCH_DETAILS_SUCCESS,
                    payload: {
                        data: resData,
                        type: searchType,
                        error: undefined,
                    },
                });
                dispatch({
                    type: types.GET_SEARCH_DETAILS_TOKENS_SUCCESS,
                    payload: {
                        data: resData.balance.tokens,
                        type: searchType,
                        error: undefined,
                    },
                });
                dispatch({
                    type: types.GET_SEARCH_DETAILS_TRANSACTIONS_SUCCESS,
                    payload: {
                        data: resData,
                        type: searchType,
                        error: undefined,
                    },
                });
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                dispatch(getTokenDetails(resData.balance.tokens, 'account'));
            } else if (searchType === 'accountTransaction') {
                dispatch({
                    type: types.GET_SEARCH_DETAILS_TRANSACTIONS_SUCCESS,
                    payload: {
                        data: resData,
                        type: searchType,
                        error: undefined,
                    },
                });
            } else if (
                searchType === 'transaction' &&
                resData.transactions[0].token_transfers &&
                resData.transactions[0].token_transfers.length > 0
            ) {
                dispatch({
                    type: types.GET_SEARCH_DETAILS_SUCCESS,
                    payload: {
                        data: resData,
                        type: searchType,
                        error: undefined,
                    },
                });
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                dispatch(getTokenDetails(resData.transactions[0].token_transfers, 'transaction'));
            } else if (
                searchType === 'transaction' &&
                resData.transactions[0].nft_transfers &&
                resData.transactions[0].nft_transfers.length > 0
            ) {
                dispatch({
                    type: types.GET_SEARCH_DETAILS_SUCCESS,
                    payload: {
                        data: resData,
                        type: searchType,
                        error: undefined,
                    },
                });
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                dispatch(getTokenDetails(resData.transactions[0].nft_transfers, 'nfts'));
            } else {
                dispatch({
                    type: types.GET_SEARCH_DETAILS_SUCCESS,
                    payload: {
                        data: resData,
                        type: searchType,
                        error: undefined,
                    },
                });
            }
        } catch (error) {
            console.log('Search Details Error', error);
            dispatch({
                type: types.GET_SEARCH_DETAILS_FAILURE,
                error: 'There was an issue fetching search details. Please try again later.',
            });
        }
    };

const getTokenDetails: ActionCreator<ThunkAction<Promise<any>, IExplorerState, null, IExplorerAction>> =
    (items: any[], type) => async (dispatch: Dispatch) => {
        dispatch({
            type: types.GET_TOKEN_DETAILS_REQUEST,
        });
        const apiCalls: any[] = [];

        items.map(async (item, i) => {
            apiCalls.push(axios.get(`${API_ROOT}/tokens/${item.token_id}`));
        });

        axios
            .all(apiCalls)
            .then((res) => {
                dispatch({
                    type: types.GET_TOKEN_DETAILS_SUCCESS,
                    payload: {
                        data: res,
                        type,
                        error: undefined,
                    },
                });
            })
            .catch((error) => {
                console.log('Search Details Error', error);
                dispatch({
                    type: types.GET_TOKEN_DETAILS_FAILURE,
                    error: 'There was an issue fetching search details. Please try again later.',
                });
            });
    };

const getNodeDetails: ActionCreator<ThunkAction<Promise<any>, IExplorerState, null, IExplorerAction>> =
    (nodeId: string) => async (dispatch: Dispatch) => {
        dispatch({
            type: types.GET_NODE_DETAILS_REQUEST,
        });

        try {
            const response = await axios({
                method: 'GET',
                url: `${API_ROOT}/network/nodes`,
                params: {
                    'node.id': nodeId,
                },
            });

            dispatch({
                type: types.GET_NODE_DETAILS_SUCCESS,
                payload: {
                    nodeDetails: response.data.nodes[0],
                    error: undefined,
                },
            });
        } catch (error) {
            console.log('Node Detail Error', error);
            dispatch({
                type: types.GET_NODE_DETAILS_FAILURE,
                error: 'There was an issue fetching node detail data. Please try again later.',
            });
        }
    };

const getTxTopicMessage: ActionCreator<ThunkAction<Promise<any>, IExplorerState, null, IExplorerAction>> =
    (consensusTime: string) => async (dispatch: Dispatch) => {
        dispatch({
            type: types.GET_TX_TOPIC_MESSAGE_REQUEST,
        });

        try {
            const response = await axios({
                method: 'GET',
                url: `${API_ROOT}/topics/messages/${consensusTime}`,
            });

            let message = 'None';
            if (response.data && response.data.message) {
                message = Buffer.from(response.data.message, 'base64').toString();
            }

            dispatch({
                type: types.GET_TX_TOPIC_MESSAGE_SUCCESS,
                payload: {
                    txTopicMessage: message,
                    error: undefined,
                },
            });
        } catch (error) {
            console.log('Tx Topic Message Error', error);
            dispatch({
                type: types.GET_TX_TOPIC_MESSAGE_FAILURE,
                error: 'There was an issue fetching transaction topic message. Please try again later.',
            });
        }
    };

const resetSearchDetails: ActionCreator<ThunkAction<Promise<any>, IExplorerState, null, IExplorerAction>> =
    () => async (dispatch: Dispatch) =>
        dispatch({
            type: types.RESET_SEARCH_DETAILS,
        });

export const searchActions: ActionCreatorsMapObject<ThunkAction<Promise<any>, IExplorerState, null, IExplorerAction>> =
    {
        getSearchDetails,
        getTokenDetails,
        getNodeDetails,
        getTxTopicMessage,
        resetSearchDetails,
    };

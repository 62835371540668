import React from 'react';
import { faqs } from './helpers/faqHelper';

// components and helpers
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Paper from '@mui/material/Paper';
import Feedback from '../Contact/Feedback';
import { useTheme } from '@mui/material/styles';

const FaqList: React.FC = () => {
    const [expanded, setExpanded] = React.useState<string | false>(false);
    const theme = useTheme();

    const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
        setExpanded(isExpanded ? panel : false);
    };

    return (
        <Grid container justifyContent={'center'} alignItems={'center'} spacing={6}>
            <Grid item xs={11} md={8}>
                <Typography
                    variant={'h4'}
                    style={{
                        fontWeight: 'bold',
                        fontFamily: 'Inter, sans-serif',
                        textAlign: 'center',
                        color: theme.palette.mode === 'light' ? '#0d0d0d' : '#FFDB58',
                    }}
                >
                    Frequently Asked Questions
                </Typography>
            </Grid>
            <Grid item xs={11} md={7}>
                <Paper
                    elevation={0}
                    style={{
                        borderRadius: 15,
                        padding: '15px 10px',
                    }}
                >
                    {faqs.map((faq, i) => {
                        return (
                            <Accordion
                                expanded={expanded === `panel${i}`}
                                onChange={handleChange(`panel${i}`)}
                                elevation={0}
                                key={`faq_${i}`}
                            >
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1bh-content"
                                    id="panel1bh-header"
                                >
                                    <Typography
                                        variant={'body2'}
                                        style={{
                                            fontWeight: 'bold',
                                            color: theme.palette.mode === 'light' ? '#0d0d0d' : '#FFDB58',
                                        }}
                                    >
                                        {faq.question}
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    {i === 7 ? (
                                        <Typography variant={'body2'}>
                                            {faq.answer}{' '}
                                            <a
                                                href="https://status.hedera.com/"
                                                target="_blank"
                                                rel="noreferrer"
                                                style={{ color: '#999BA8' }}
                                            >
                                                https://status.hedera.com/
                                            </a>
                                            .
                                        </Typography>
                                    ) : (
                                        <Typography variant={'body2'}>{faq.answer}</Typography>
                                    )}
                                </AccordionDetails>
                            </Accordion>
                        );
                    })}
                </Paper>
            </Grid>
            {/*<Grid item xs={11} md={6}>*/}
            {/*    <Typography*/}
            {/*        variant={'h6'}*/}
            {/*        color={'textSecondary'}*/}
            {/*        style={{ fontWeight: 'bold', fontFamily: 'Inter, sans-serif', textAlign: 'center' }}*/}
            {/*    >*/}
            {/*        Have any other questions? Feel free to contact us:*/}
            {/*    </Typography>*/}
            {/*</Grid>*/}
            <Grid item xs={11} md={7}>
                <Paper
                    elevation={0}
                    style={{
                        borderRadius: 15,
                        padding: '15px 10px',
                    }}
                >
                    <Feedback />
                </Paper>
            </Grid>
        </Grid>
    );
};

export default FaqList;

import * as React from 'react';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';

// components and helpers
import { LineChart } from '@acoer/ac-js-lib-charts';
import { explorerActions } from '../Explorer/ExplorerAction';

// mui
import Paper from '@mui/material/Paper';
import { useTheme } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import TxDayChartsSkeletonLoading from '../Shared/LoadingSkeletons/TxDayChartsSkeletonLoading';

const TxLineChart: React.FC = () => {
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('sm'));
    const dispatch = useDispatch();
    const priceData = useSelector((state: RootStateOrAny) => state.explorer.priceData);
    const priceDataLoading = useSelector((state: RootStateOrAny) => state.explorer.priceDataLoading);
    const [yMin, setYMin] = React.useState<number>(0);
    const [yMax, setYMax] = React.useState<number>(0);

    useEffect(() => {
        dispatch(explorerActions?.getPriceData());
    }, []);

    useEffect(() => {
        if (priceData && priceData.length && priceData[0].data) {
            const myArray = priceData[0].data;
            const min = Math.min(...myArray.map((item: { y: any }) => item.y));
            const max = Math.max(...myArray.map((item: { y: any }) => item.y));
            setYMin(min);
            setYMax(max);
        }
    }, [priceData]);

    if (priceDataLoading) return <TxDayChartsSkeletonLoading />;
    return (
        <Paper
            style={{
                height: 270,
                borderRadius: 15,
                padding: matches ? '20px 20px 40px 20px' : '15px 5px 15px 5px',
            }}
            elevation={0}
        >
            <LineChart
                data={priceData}
                onDownloadCsv={() => console.log('download')}
                chartTitle={'Price Change in Last 24 Hours'}
                primaryText={theme.palette.mode === 'light' ? theme.palette.text.primary : '#D3D3D3'}
                customClasses={{ fontWeight: 'bold', fontFamily: 'Inter, sans-serif' }}
                customVariant={'h6'}
                showToolbar={matches}
                colourScheme={['#58A3FF']}
                chartTheme={theme.palette.mode}
                currency={true}
                tickAmount={12}
                yLegend={'USD'}
                minimumY={yMin}
                maximumY={yMax}
                yFormatter={(value: number) => `$${value.toFixed(4)}`}
            />
        </Paper>
    );
};

export default TxLineChart;

import React from 'react';
import { features } from './helpers/featuresHelper';

// components and helpers
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import ManageHistoryIcon from '@mui/icons-material/ManageHistory';
import TimelineIcon from '@mui/icons-material/Timeline';
import AssessmentIcon from '@mui/icons-material/Assessment';
import DashboardIcon from '@mui/icons-material/Dashboard';
import { useTheme } from '@mui/material';
import DemoDashboard from './DemoDashboard';
import useMediaQuery from '@mui/material/useMediaQuery';
import Feedback from '../Contact/Feedback';

const AdditionalFeatures: React.FC = () => {
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('sm'));

    return (
        <Grid
            container
            justifyContent={'center'}
            alignItems={'center'}
            spacing={6}
            style={{ padding: 0, margin: 0, width: '100%' }}
        >
            <Grid item xs={12} md={8}>
                <Typography
                    variant={'h4'}
                    style={{
                        fontWeight: 'bold',
                        fontFamily: 'Inter, sans-serif',
                        textAlign: 'center',
                        color: theme.palette.mode === 'light' ? '#0d0d0d' : '#FFDB58',
                    }}
                >
                    Advanced Features
                </Typography>
            </Grid>
            <Grid item xs={matches ? 11 : 12}>
                <Grid container spacing={matches ? 4 : 1} alignItems={'center'}>
                    {features.map((feature, i: number) => {
                        return (
                            <Grid item xs={6} sm={6} md={3} key={`feature_${i}`}>
                                <Card
                                    sx={{
                                        borderRadius: 5,
                                        textAlign: 'center',
                                    }}
                                    elevation={0}
                                >
                                    <Box>
                                        <CardContent>
                                            {feature.icon === 'historical' ? (
                                                <ManageHistoryIcon
                                                    style={{ fontSize: 60, color: theme.palette.primary.main }}
                                                />
                                            ) : feature.icon === 'timeframe' ? (
                                                <TimelineIcon
                                                    style={{ fontSize: 60, color: theme.palette.primary.main }}
                                                />
                                            ) : feature.icon === 'reporting' ? (
                                                <AssessmentIcon
                                                    style={{ fontSize: 60, color: theme.palette.primary.main }}
                                                />
                                            ) : (
                                                <DashboardIcon
                                                    style={{ fontSize: 60, color: theme.palette.primary.main }}
                                                />
                                            )}
                                            <Typography gutterBottom variant="h6" component="div">
                                                {feature.title}
                                            </Typography>
                                        </CardContent>
                                    </Box>
                                </Card>
                            </Grid>
                        );
                    })}
                </Grid>
            </Grid>
            <Grid item xs={12} md={8}>
                <Typography
                    variant={'body1'}
                    color={'textPrimary'}
                    style={{ fontWeight: 'bold', fontFamily: 'Inter, sans-serif', textAlign: 'center' }}
                >
                    Upgrade your HederaExplorer experience with advanced features.
                </Typography>
                <Typography
                    variant={'body1'}
                    color={'textPrimary'}
                    style={{ fontWeight: 'bold', fontFamily: 'Inter, sans-serif', textAlign: 'center' }}
                >
                    We have provided a demo below of what your explorer could look like with some of our advanced
                    features included.
                </Typography>
            </Grid>
            <Grid item xs={matches ? 11 : 12}>
                <DemoDashboard />
            </Grid>
            <Grid item xs={12} md={8}>
                <Typography
                    variant={'body1'}
                    color={'textPrimary'}
                    style={{ fontWeight: 'bold', fontFamily: 'Inter, sans-serif', textAlign: 'center' }}
                >
                    By tapping into our additional features, the power of the entire Hedera ledger is at your
                    fingertips. You will have the ability to drill down, filter, and create customized reports for all
                    your needs.
                </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
                <Typography
                    variant={'h6'}
                    color={'textSecondary'}
                    style={{ fontWeight: 'bold', fontFamily: 'Inter, sans-serif', textAlign: 'center' }}
                >
                    To get more information about our advanced options, please contact us:
                </Typography>
            </Grid>
            <Grid item xs={12} md={7}>
                <Paper
                    elevation={0}
                    style={{
                        borderRadius: 15,
                        padding: '15px 10px',
                    }}
                >
                    <Feedback />
                </Paper>
            </Grid>
        </Grid>
    );
};

export default AdditionalFeatures;

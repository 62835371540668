import logo from '../../../assets/images/HederaExplorerLogo.svg';

interface ThemeConfig {
    logo: any;
}

export const getThemeConfig = (): ThemeConfig => {
    return {
        logo: logo,
    };
};

import React, { useContext } from 'react';
import { Hbar } from '@hashgraph/sdk';
import axios from 'axios';
import history from '../../Shared/history';
import filesize from 'filesize';

// component and helper imports
import { HocContext } from '../../../common/context/hocContext';
import { entityFormat, typeFormat } from './utils';
import utils from '../../../common/utils/utils';
import { objectListItem } from './objectListItem';
import { dateTime, dateDays, crypto, objectIgnore, detailLinks, copyData, byteData } from './itemTypes';

// mui
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Button from '@mui/material/Button';
import { useTheme } from '@mui/material/styles';
import { AppContext } from '../../../containers/AppProviderWrapper';
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded';
import HighlightOffRoundedIcon from '@mui/icons-material/HighlightOffRounded';

export const createDetailsList = (
    contents: any,
    copyShow: string,
    setCopyShow: (value: string) => void,
    type: string,
    typeDetail?: string | number,
) => {
    const theme = useTheme();
    const { unitValue } = useContext(HocContext);
    const { hederaNodeData } = useContext(AppContext);
    const handleLink = (value: string) => {
        const API_ROOT = process.env.REACT_APP_OBJECT_TYPE;
        let searchValue = value;
        if (value.includes('Acct ID')) {
            const initialSplit = value.split(':')[1];
            searchValue = initialSplit.split(')')[0].trim();
        }
        axios.get(`${API_ROOT}/${searchValue}`).then((res) => {
            if (res.data.found) {
                history.push(`/search-details/${res.data.type}/${searchValue}`);
            }
        });
    };
    const handleCopy = (data: string) => {
        const copyData = data.split(' ').join('');
        navigator.clipboard.writeText(copyData);
    };

    const copyButton = (data: any, key: string) => {
        return (
            <div
                style={{
                    position: 'absolute',
                    zIndex: 110,
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: key !== 'bytecode' && key !== 'public_key' ? '100%' : 150,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <Button
                    variant={'contained'}
                    style={{
                        fontSize: 10,
                        backgroundColor: theme.palette.background.paper,
                        color: theme.palette.mode === 'light' ? '#0d0d0d' : '#FFDB58',
                    }}
                    size={'small'}
                    onClick={() => handleCopy(data)}
                >
                    Copy to Clipboard
                </Button>
            </div>
        );
    };

    if (contents) {
        const list: any = [];
        contents.map((k: string, i: number) => {
            let data: any = k[1];
            if (k[0] === 'size') {
                data = filesize(k[1] as unknown as number);
            } else if (typeof k[1] == 'boolean') {
                data = (k[1] as boolean).toString();
            } else if (k[0] === 'staked_node_id') {
                if (k[1] === null) {
                    data = 'None';
                } else {
                    const matchingNode = hederaNodeData && hederaNodeData.filter((x: any) => x.id === k[1].toString());
                    data = `${k[1]} (Acct ID: ${matchingNode[0].accountId}) - ${matchingNode[0].organization}`;
                }
            } else if ((k[1] == null || !k[1]) && k[0] !== 'node_id' && !crypto.includes(k[0])) {
                data = 'None';
            } else if (k[0] === 'result') {
                {
                    k[1] === 'SUCCESS'
                        ? (data = (
                              <CheckCircleOutlineRoundedIcon
                                  style={{ color: theme.palette.mode === 'light' ? '#2DDD91' : '#3BF2A3' }}
                              />
                          ))
                        : (data = (
                              <Grid container spacing={1} alignItems={'center'}>
                                  <Grid item>
                                      <HighlightOffRoundedIcon style={{ color: '#FF585B' }} />{' '}
                                  </Grid>
                                  <Grid item>
                                      <Typography
                                          variant={'body2'}
                                          style={{
                                              fontFamily:
                                                  'Courier New,Courier,Lucida Sans Typewriter,Lucida Typewriter,monospace',
                                              wordBreak: 'break-all',
                                          }}
                                      >
                                          {k[1].replaceAll('_', ' ')}
                                      </Typography>
                                  </Grid>
                              </Grid>
                          ));
                }
            }
            if (crypto.includes(k[0])) {
                if (k[1] && type !== 'token_transfers' && type !== 'token' && type !== 'nftTransfers') {
                    const hbar = Hbar.fromTinybars(k[1]).toString().split(' ');
                    const formattedHbar =
                        parseFloat(hbar[0]).toLocaleString(undefined, { maximumFractionDigits: 6 }) + ' ' + hbar[1];
                    {
                        unitValue === 'true'
                            ? (data = `${parseFloat(k[1])?.toLocaleString()} tℏ`)
                            : (data = formattedHbar);
                    }
                } else if (k[1] && k[1] !== '0' && type === 'token') {
                    const fixedValue = parseInt(typeDetail as string);
                    if (fixedValue) {
                        const leadValue = k[1].slice(0, -[typeDetail]);
                        const decimalValue = k[1].slice(k[1].length - fixedValue, k[1].length);
                        data = parseFloat(`${leadValue}.${decimalValue}`).toLocaleString(undefined, {
                            minimumFractionDigits: fixedValue,
                        });
                    } else {
                        data = k[1].toLocaleString();
                    }
                } else if (k[1] && k[1] !== '0' && type === 'token_transfers') {
                    data = parseFloat(k[1]).toLocaleString();
                } else if (k[1] == '0') {
                    {
                        unitValue === 'true'
                            ? (data = `${parseFloat(k[1])?.toLocaleString()} tℏ`)
                            : (data = Hbar.fromTinybars(k[1])?.toLocaleString());
                    }
                } else {
                    data = 'None';
                }
            }
            if (dateTime.includes(k[0])) {
                if (k[1]) {
                    data = new Date(parseFloat(k[1]) * 1000).toLocaleString();
                } else {
                    data = 'None';
                }
            }
            if (dateDays.includes(k[0])) {
                if (k[1]) {
                    data = utils.secondsToDhms(parseFloat(k[1]));
                } else {
                    data = 'None';
                }
            }
            if (byteData.includes(k[0])) {
                if (k[1]) {
                    data = Buffer.from(k[1], 'base64').toString();
                } else {
                    data = 'None';
                }
            }
            if (typeof k[1] === 'object' && k[1] !== null) {
                if (!objectIgnore.includes(k[0])) {
                    if (Array.isArray(k[1])) {
                        (k[1] as any[]).map((document: any, i: number) => {
                            const docTitle = `${utils.toTitleText(k[0])} ${i + 1}`;
                            list.push(objectListItem(document, docTitle, handleCopy, copyShow, setCopyShow));
                        });
                    } else {
                        const docTitle = typeFormat(k[0]);
                        list.push(objectListItem(k[1], docTitle, handleCopy, copyShow, setCopyShow));
                    }
                }
            } else {
                k[0] !== '__typename' &&
                    k[0] !== 'is_approval' &&
                    list.push(
                        <Grid item xs={12} md={6} key={`list-item-${i}`}>
                            <Grid container alignItems={'center'} alignContent={'center'}>
                                <Grid item xs={5}>
                                    <ListItem dense>
                                        <ListItemText
                                            primary={
                                                <Typography
                                                    variant={'body2'}
                                                    style={{ fontWeight: 'bold', fontFamily: 'Inter, sans-serif' }}
                                                >
                                                    {k[0] === 'entity_id'
                                                        ? entityFormat(typeDetail as string)
                                                        : typeFormat(k[0])}
                                                </Typography>
                                            }
                                        />
                                    </ListItem>
                                </Grid>
                                <Grid
                                    item
                                    xs={7}
                                    style={{ wordBreak: 'break-all', overflowY: 'scroll', maxHeight: 150 }}
                                >
                                    <ListItem
                                        dense
                                        sx={[
                                            (theme) => ({
                                                '&:hover': {
                                                    backgroundColor:
                                                        copyData.includes(k[0]) && data !== 'None'
                                                            ? theme.palette.background.default
                                                            : null,
                                                },
                                            }),
                                        ]}
                                        onMouseEnter={() => setCopyShow(k[0])}
                                        onMouseLeave={() => setCopyShow('')}
                                    >
                                        {copyShow === k[0] &&
                                            data !== 'None' &&
                                            copyData.includes(k[0]) &&
                                            copyButton(data, k[0])}
                                        <ListItemText
                                            primary={
                                                <Typography
                                                    component={'span'}
                                                    variant={'body2'}
                                                    sx={[
                                                        {
                                                            fontFamily:
                                                                'Courier New,Courier,Lucida Sans Typewriter,Lucida Typewriter,monospace',
                                                            borderBottom:
                                                                detailLinks.includes(k[0]) &&
                                                                data !== 'None' &&
                                                                data !== 'MINT' &&
                                                                data !== 'BURN'
                                                                    ? '1px dotted'
                                                                    : 'none',
                                                            textDecorationStyle:
                                                                detailLinks.includes(k[0]) &&
                                                                data !== 'None' &&
                                                                data !== 'MINT' &&
                                                                data !== 'BURN'
                                                                    ? 'dotted'
                                                                    : 'none',
                                                        },
                                                        (theme) => ({
                                                            '&:hover': {
                                                                color:
                                                                    detailLinks.includes(k[0]) &&
                                                                    data !== 'None' &&
                                                                    data !== 'MINT' &&
                                                                    data !== 'BURN'
                                                                        ? theme.palette.primary.main
                                                                        : null,
                                                                cursor:
                                                                    (detailLinks.includes(k[0]) ||
                                                                        copyData.includes(k[0])) &&
                                                                    data !== 'None' &&
                                                                    data !== 'MINT' &&
                                                                    data !== 'BURN' &&
                                                                    (detailLinks.includes(k[0]) ||
                                                                        copyData.includes(k[0]))
                                                                        ? 'pointer'
                                                                        : 'auto',
                                                            },
                                                        }),
                                                    ]}
                                                    onClick={() =>
                                                        detailLinks.includes(k[0]) && data !== 'None'
                                                            ? handleLink(data)
                                                            : undefined
                                                    }
                                                >
                                                    {type === 'transaction' && k[0] === 'name'
                                                        ? typeFormat(data).toUpperCase()
                                                        : typeof data === 'string' &&
                                                          k[0] !== 'memo_base64' &&
                                                          k[0] !== 'memo' &&
                                                          !dateTime.includes(k[0]) &&
                                                          !crypto.includes(k[0]) &&
                                                          data !== 'None' &&
                                                          type === 'token' &&
                                                          k[0] !== 'name' &&
                                                          type === 'token' &&
                                                          k[0] !== 'symbol'
                                                        ? typeFormat(data)
                                                        : data}
                                                </Typography>
                                            }
                                        />
                                    </ListItem>
                                </Grid>
                            </Grid>
                        </Grid>,
                    );
            }
        });

        return (
            <Grid container direction="row" spacing={2}>
                {list}
            </Grid>
        );
    }
};

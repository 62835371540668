import React from 'react';
import { Timeline } from 'react-twitter-widgets';

// mui
import Paper from '@mui/material/Paper';
import { useTheme } from '@mui/material';

const TwitterFeed: React.FC = () => {
    const theme = useTheme();
    return (
        <Paper
            elevation={0}
            style={{ height: 740, padding: 5, borderRadius: 15, overflow: 'scroll' }}
            id={'twitter_feed'}
        >
            <Timeline
                dataSource={{
                    sourceType: 'profile',
                    screenName: 'hashgraph',
                }}
                options={{
                    width: '100%',
                    height: 720,
                    theme: theme.palette.mode,
                    chrome: 'nofooter',
                }}
            />
        </Paper>
    );
};

export default TwitterFeed;

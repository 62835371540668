import React, { useContext } from 'react';
import moment from 'moment';
import TimeAgo from 'react-timeago';
import { Hbar } from '@hashgraph/sdk';

// component and helper imports
import { HocContext } from '../../../common/context/hocContext';
import { copyData, keys } from './itemTypes';

// mui
import Grid from '@mui/material/Grid';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { useTheme } from '@mui/material/styles';

export const objectListItem = (
    document: any,
    docTitle: string,
    handleCopy: (data: string) => void,
    copyShow: string,
    setCopyShow: (value: string) => void,
) => {
    const { unitValue } = useContext(HocContext);
    const theme = useTheme();
    let secondaryTitle: any;
    let data = '';
    if (docTitle === 'Balance') {
        const date = moment.unix(document.timestamp);
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        secondaryTitle = <TimeAgo date={date} />;
        if (document.balance && document.balance.toString()) {
            const hbar = Hbar.fromTinybars(document.balance).toString().split(' ');
            const formattedHbar =
                parseFloat(hbar[0]).toLocaleString(undefined, { maximumFractionDigits: 6 }) + ' ' + hbar[1];
            data = unitValue === 'true' ? (data = `${document.balance?.toLocaleString()} tℏ`) : (data = formattedHbar);
        } else {
            data = 'None';
        }
    } else if (keys.includes(docTitle)) {
        secondaryTitle = document._type;
        if (document.key) {
            data = document.key.match(/.{1,4}/g).join(' ');
        } else {
            data = 'None';
        }
    }

    const copyButton = (data: any) => {
        return (
            <div
                style={{
                    position: 'absolute',
                    zIndex: 110,
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <Button
                    variant={'contained'}
                    style={{
                        fontSize: 10,
                        backgroundColor: theme.palette.background.paper,
                        color: theme.palette.mode === 'light' ? '#0d0d0d' : '#FFDB58',
                    }}
                    size={'small'}
                    onClick={() => handleCopy(data)}
                >
                    Copy to Clipboard
                </Button>
            </div>
        );
    };

    if (docTitle === 'Custom Fees') return null;
    return (
        <Grid item xs={12} md={6} key={`list-item-${docTitle}`}>
            <Grid container>
                <Grid item xs={data ? 5 : 12}>
                    {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                    {/*@ts-ignore*/}
                    <ListItem button={!data} onClick={() => handleDocClick(docTitle)}>
                        <ListItemText
                            primary={
                                <Typography variant={'body2'} style={{ fontWeight: 'bold' }}>
                                    {docTitle}
                                </Typography>
                            }
                            secondary={
                                secondaryTitle ? (
                                    <Typography variant={'caption'} color={'primary'}>
                                        {secondaryTitle}
                                    </Typography>
                                ) : (
                                    ''
                                )
                            }
                        />
                    </ListItem>
                </Grid>
                {data && (
                    <Grid item xs={7}>
                        <ListItem
                            dense
                            sx={(theme) => ({
                                '&:hover': {
                                    backgroundColor: copyData.includes(docTitle)
                                        ? theme.palette.background.default
                                        : null,
                                },
                            })}
                            onMouseEnter={() => setCopyShow(docTitle)}
                            onMouseLeave={() => setCopyShow('')}
                        >
                            {copyShow === docTitle &&
                                copyData.includes(docTitle) &&
                                data !== 'None' &&
                                copyButton(data)}
                            <ListItemText
                                primary={
                                    <Typography
                                        variant={'body2'}
                                        style={{
                                            fontFamily:
                                                'Courier New,Courier,Lucida Sans Typewriter,Lucida Typewriter,monospace',
                                            wordBreak: 'break-all',
                                        }}
                                    >
                                        {data}
                                    </Typography>
                                }
                            />
                        </ListItem>
                    </Grid>
                )}
            </Grid>
        </Grid>
    );
};

import React, { useEffect, useState } from 'react';
import MUIDataTable from 'mui-datatables';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import { useNavigate, createSearchParams } from 'react-router-dom';

// components and helpers
import datatableTheme from '../../assets/styling/DatatableStyles';
import mobileDatatableTheme from '../../assets/styling/MobileDatatableStyles';
import { typeFormat } from '../Search/helpers/utils';
import Breadcrumbs from '../Shared/Breadcrumbs';
import Loader from '../Search/helpers/Loader';
import { transactionActions } from './TransactionsAction';
import TransactionCustomToolbar from './TransactionCustomToolbar';

// mui
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Tooltip from '@mui/material/Tooltip';
import { ThemeProvider, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import Typography from '@mui/material/Typography';
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded';
import HighlightOffRoundedIcon from '@mui/icons-material/HighlightOffRounded';

const muiCache = createCache({
    key: 'mui-datatables',
    prepend: true,
});
const TransactionsList: React.FC = () => {
    const theme = useTheme();
    const navigate = useNavigate();
    const matches = useMediaQuery(theme.breakpoints.up('sm'));
    const [pageLimit] = useState<number>(25);
    const [page, setPage] = useState<number>(0);
    const [previousList, setPreviousList] = useState<any>();
    const [currentList, setCurrentList] = useState<any>();
    const [currentNext, setCurrentNext] = useState<any>();
    const [previousNext, setPreviousNext] = useState<any>();
    const [typeFilter, setTypeFilter] = useState<string>('ALL');
    const transactionData = useSelector((state: RootStateOrAny) => state.transactions.transactionData);
    const transactionDataLoading = useSelector((state: RootStateOrAny) => state.transactions.transactionDataLoading);
    const dispatch = useDispatch();
    const tableTheme = {
        ...theme,
        components: matches ? datatableTheme : mobileDatatableTheme,
    };

    useEffect(() => {
        if (window.location.search) {
            const filter = window.location.search.split('=').pop();
            if (filter) {
                setTypeFilter(filter.toUpperCase());
                dispatch(transactionActions?.getTransactions(25, 'desc', null, filter));
            }
        } else {
            dispatch(transactionActions?.getTransactions(25, 'desc'));
        }
    }, []);

    useEffect(() => {
        if (transactionData) {
            setCurrentList(transactionData.transactions);
            setCurrentNext(transactionData.links.next);
        }
    }, [transactionData]);

    const handleRowClick = (data: any[]) => {
        const value = data[0];
        navigate(`/transactions/transaction-details/${value}`);
    };

    const handleTableChange = (action: string, tableState: any) => {
        if (action !== 'propsUpdate' && action === 'changePage') {
            const nextPage = tableState.page;
            let filter: any = typeFilter;
            if (typeFilter === 'ALL') {
                filter = null;
            }
            if (nextPage > page && currentNext) {
                const nextUrl = currentNext.split('desc').pop();
                setPreviousList({
                    ...previousList,
                    [page]: currentList,
                });
                setPreviousNext({
                    ...previousNext,
                    [page]: currentNext,
                });
                dispatch(transactionActions?.getTransactions(25, 'desc', nextUrl, filter));
                setPage(nextPage);
            } else {
                if (page - 1 > 0) {
                    setCurrentList(previousList[page - 1]);
                    setCurrentNext(previousNext[page - 1]);
                    setPage(page - 1);
                } else {
                    setPreviousList({});
                    setPreviousNext({});
                    dispatch(transactionActions?.getTransactions(25, 'desc', null, filter));
                    setPage(0);
                }
            }
        }
    };

    const handleChangeFilter = (data: string) => {
        setTypeFilter(data);
        setPreviousList({});
        setPreviousNext({});
        setPage(0);
        if (data === 'ALL') {
            dispatch(transactionActions?.getTransactions(25, 'desc', null, null));
            const params = {
                type: '',
            };
            const options = {
                pathname: window.location.pathname,
                search: `?${createSearchParams(params)}`,
            };
            navigate(options, { replace: true });
        } else {
            dispatch(transactionActions?.getTransactions(25, 'desc', null, data));
            const params = {
                type: data.toLowerCase(),
            };
            const options = {
                pathname: window.location.pathname,
                search: `?${createSearchParams(params)}`,
            };
            navigate(options, { replace: true });
        }
    };

    const columns = [
        {
            name: 'transaction_id',
            label: 'ID',
            options: {
                filter: false,
                sort: false,
                setCellProps: () => ({
                    style: {
                        fontFamily: 'Inter, sans-serif',
                        fontSize: 12,
                        width: !matches ? '100%' : '30%',
                    },
                }),
            },
        },
        {
            name: 'node',
            label: 'Node',
            options: {
                filter: false,
                sort: false,
                setCellProps: () => ({
                    style: {
                        fontFamily: 'Inter, sans-serif',
                        fontSize: 12,
                        width: !matches ? '100%' : '10%',
                    },
                }),
            },
        },
        {
            name: 'name',
            label: 'Type',
            options: {
                filter: false,
                sort: false,
                setCellProps: () => ({
                    style: {
                        fontFamily: 'Inter, sans-serif',
                        fontSize: 12,
                        width: !matches ? '100%' : '30%',
                    },
                }),
                // eslint-disable-next-line react/display-name
                customBodyRender: (value: string) => {
                    return typeFormat(value);
                },
            },
        },
        {
            name: 'result',
            label: 'Result',
            options: {
                filter: false,
                sort: false,
                setCellProps: () => ({
                    style: {
                        fontFamily: 'Inter, sans-serif',
                        fontSize: 12,
                        width: !matches ? '100%' : '10%',
                    },
                }),
                // eslint-disable-next-line react/display-name
                customBodyRender: (value: string) => {
                    return (
                        <Grid
                            container
                            spacing={1}
                            style={{
                                margin: 0,
                                width: '100%',
                            }}
                        >
                            <Grid item>
                                {value === 'SUCCESS' ? (
                                    <CheckCircleOutlineRoundedIcon
                                        style={{ color: theme.palette.mode === 'light' ? '#2DDD91' : '#3BF2A3' }}
                                    />
                                ) : (
                                    <Tooltip title={value.replaceAll('_', ' ')}>
                                        <HighlightOffRoundedIcon style={{ color: '#FF585B' }} />
                                    </Tooltip>
                                )}
                            </Grid>
                        </Grid>
                    );
                },
            },
        },
        {
            name: 'consensus_timestamp',
            label: 'Consensus',
            options: {
                filter: false,
                sort: false,
                setCellProps: () => ({
                    style: {
                        fontFamily: 'Inter, sans-serif',
                        fontSize: 12,
                    },
                }),
                // eslint-disable-next-line react/display-name
                customBodyRender: (value: string) => {
                    return new Date(parseFloat(value) * 1000).toLocaleString();
                },
            },
        },
    ];

    const options = {
        onRowClick: handleRowClick,
        elevation: 0,
        download: false,
        filter: false,
        pagination: true,
        print: false,
        search: false,
        selectableRowsHeader: false,
        selectableRowsHideCheckboxes: true,
        viewColumns: false,
        rowsPerPage: pageLimit,
        rowsPerPageOptions: [25],
        serverSide: true,
        page,
        count: currentNext ? 1000000000 : page > 0 && previousNext[page - 1] && page > 0 ? 25 : 10,
        onTableChange: (action: string, tableState: any) => handleTableChange(action, tableState),
        textLabels: {
            body: {
                noMatch: !transactionDataLoading && 'Sorry, no matching records found',
            },
        },
        customToolbar: () => {
            return <TransactionCustomToolbar value={typeFilter} handleChangeFilter={handleChangeFilter} />;
        },
    };

    const loadingComponent = (
        <div
            style={{
                position: 'absolute',
                zIndex: 110,
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                background: `${theme.palette.background.paper}80`,
            }}
        >
            <Loader size={60} color={theme.palette.primary.main} />
        </div>
    );
    return (
        <Grid container justifyContent={'center'} spacing={2}>
            <Grid item xs={10}>
                <Breadcrumbs />
            </Grid>
            <Grid item xs={10}>
                <CacheProvider value={muiCache}>
                    <ThemeProvider theme={tableTheme}>
                        <div style={{ position: 'relative' }}>
                            {(transactionDataLoading || !currentList) && loadingComponent}
                            <Paper elevation={0} style={{ padding: 20, borderRadius: 15 }} id={'serverList'}>
                                <MUIDataTable
                                    title={
                                        <Typography
                                            variant={'h6'}
                                            style={{
                                                fontWeight: 'bold',
                                                fontFamily: 'Inter, sans-serif',
                                                color: theme.palette.mode === 'light' ? '#0d0d0d' : '#FFDB58',
                                                marginLeft: -25,
                                            }}
                                        >
                                            Transactions
                                        </Typography>
                                    }
                                    data={currentList}
                                    columns={columns}
                                    options={options}
                                />
                            </Paper>
                        </div>
                    </ThemeProvider>
                </CacheProvider>
            </Grid>
        </Grid>
    );
};

export default TransactionsList;

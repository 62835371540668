import React, { useContext, useEffect, useState } from 'react';
import { Hbar, HbarUnit } from '@hashgraph/sdk';

// chart lib
import { LineChart, BarChart } from '@acoer/ac-js-lib-charts';

// dashboard data
import accountData from './helpers/dashboard/account.json';
import accountCreation from './helpers/dashboard/account-creation.json';
import marketData from './helpers/dashboard/market-data.json';
import nodeNames from './helpers/dashboard/node-names.json';
import nodeStats from './helpers/dashboard/node-stats.json';
import smartContract from './helpers/dashboard/smart-contract.json';
import stable from './helpers/dashboard/stable.json';
import token from './helpers/dashboard/token.json';
import topic from './helpers/dashboard/topic.json';
import tps from './helpers/dashboard/tps.json';
import transactionValue from './helpers/dashboard/transaction-value.json';
import transactionVolume from './helpers/dashboard/transaction-volume.json';
import transfers from './helpers/dashboard/transfers.json';
import utils from '../../common/utils/utils';
import { HocContext } from '../../common/context/hocContext';
import datatableTheme from '../../assets/styling/DatatableStyles';
import datatableDarkTheme from '../../assets/styling/DatatableDarkStyles';
import mobileDatatableTheme from '../../assets/styling/MobileDatatableStyles';
import mobileDatatableDarkTheme from '../../assets/styling/MobileDatatableDarkStyles';

// mui
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { CacheProvider } from '@emotion/react';
import { ThemeProvider } from '@mui/material/styles';
import MUIDataTable from 'mui-datatables';
import createCache from '@emotion/cache';

const muiCache = createCache({
    key: 'mui-datatables',
    prepend: true,
});
const DemoDashboard: React.FC = () => {
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('sm'));
    const matchesMed = useMediaQuery(theme.breakpoints.up('md'));
    const { unitValue } = useContext(HocContext);
    const [priceData, setPriceData] = useState<{ id: string; data: { x: any; y: any }[] }[]>([]);
    const [txPerSec, setTxPerSec] = useState<{ id: string; data: { x: any; y: any }[] }[]>([]);
    const [stableData, setStableData] = useState<{ name: string; nbOfTransactions: number; volume: number }[]>([]);
    const [stableBarData, setStableBarData] = useState<any[]>([]);
    const [accountCreationData, setAccountCreationData] = useState<any[]>([]);
    const [txVolumeData, setTxVolumeData] = useState<any[]>([]);
    const [txValueData, setTxValueData] = useState<any[]>([]);
    const [nodeData, setNodeData] = useState<any[]>([]);
    const tableTheme = {
        ...theme,
        components: matches
            ? theme.palette.mode === 'dark'
                ? datatableDarkTheme
                : datatableTheme
            : theme.palette.mode === 'dark'
            ? mobileDatatableDarkTheme
            : mobileDatatableTheme,
    };

    useEffect(() => {
        const price: { id: string; data: { x: any; y: any }[] }[] = [{ id: 'price', data: [] }];
        const stableBar: any[] = [];
        const creationData: any[] = [];
        const volumeData: any[] = [];
        const valueData: any[] = [];
        const txSec: { id: string; data: { x: any; y: any }[] }[] = [{ id: 'transaction', data: [] }];

        // price data
        marketData.map((data: any) => {
            const date = data.timestamp;
            const [mm, dd, yyyy] = date.split('-');
            const reformattedDate = `${dd}-${mm}-${yyyy}`;
            const dataPush = {
                x: reformattedDate,
                y: data.price,
            };
            price[0].data.push(dataPush);
        });
        setPriceData(price);

        // tx per second data
        tps.map((data: any) => {
            const date = data.timestamp;
            const [mm, dd, yyyy] = date.split('-');
            const reformattedDate = `${dd}-${mm}-${yyyy}`;
            const dataPush = {
                x: reformattedDate,
                y: data.value,
            };
            txSec[0].data.push(dataPush);
        });
        setTxPerSec(txSec);

        // stable coin data
        const stableTotal = stable['USDC'].nbOfTransactions.reduce((a: any, b: any) => +a + +b.value, 0);
        const sData = [
            {
                name: 'USDC',
                id: stable['USDC'].id,
                nbOfTransactions: stableTotal,
                volume: stable['USDC'].volume,
            },
        ];
        setStableData(sData);
        stable['USDC'].nbOfTransactions.map((data: any) => {
            const date = data.timestamp;
            const [yyyy, dd, mm] = date.split('-');
            const reformattedDate = `${dd}-${mm}-${yyyy}`;
            const sBar = {
                groupedBy: reformattedDate,
                value: data.value,
            };
            stableBar.push(sBar);
        });
        setStableBarData(stableBar);

        // account creation data
        accountCreation.map((data: any) => {
            const date = data.timestamp;
            const [yyyy, dd, mm] = date.split('-');
            const reformattedDate = `${dd}-${mm}-${yyyy}`;
            const acdata = {
                groupedBy: reformattedDate,
                value: data.value,
            };
            creationData.push(acdata);
        });
        setAccountCreationData(creationData);

        // transaction volume data
        transactionVolume.map((data: any) => {
            const date = data.timestamp;
            const [yyyy, dd, mm] = date.split('-');
            const reformattedDate = `${dd}-${mm}-${yyyy}`;
            const txVolume = {
                groupedBy: reformattedDate,
                value: data.value,
            };
            volumeData.push(txVolume);
        });
        setTxVolumeData(volumeData);

        // transaction value data
        transactionValue.map((data: any) => {
            const date = data.timestamp;
            const [yyyy, dd, mm] = date.split('-');
            const reformattedDate = `${dd}-${mm}-${yyyy}`;
            const txValue = {
                groupedBy: reformattedDate,
                value: data.value,
            };
            valueData.push(txValue);
        });
        setTxValueData(valueData);

        // node data
        const completeNodeData = nodeNames.map((item, i) =>
            Object.assign({}, item, ...nodeStats.filter((x: any) => x.nodeId === item.nodeId)),
        );
        setNodeData(completeNodeData);
    }, []);

    const tokenColumns = [
        {
            name: 'name',
            label: 'Name',
            options: {
                sort: false,
                // eslint-disable-next-line react/display-name
                customBodyRender: (value: any) => {
                    return (
                        <Typography variant={'caption'} style={{ fontFamily: 'Inter, sans-serif' }}>
                            {value}
                        </Typography>
                    );
                },
            },
        },
        {
            name: 'id',
            label: 'ID',
            options: {
                sort: false,
                // eslint-disable-next-line react/display-name
                customBodyRender: (value: any) => {
                    return (
                        <Typography variant={'caption'} style={{ fontFamily: 'Inter, sans-serif' }}>
                            {value}
                        </Typography>
                    );
                },
            },
        },
        {
            name: 'nbOfTransactions',
            label: 'Number of Transactions',
            options: {
                sort: false,
                // eslint-disable-next-line react/display-name
                customBodyRender: (value: any) => {
                    return (
                        <Typography variant={'caption'} style={{ fontFamily: 'Inter, sans-serif' }}>
                            {value.toLocaleString()}
                        </Typography>
                    );
                },
            },
        },
        {
            name: 'volume',
            label: 'Volume',
            options: {
                sort: false,
                // eslint-disable-next-line react/display-name
                customBodyRender: (value: any) => {
                    return (
                        <Typography variant={'caption'} style={{ fontFamily: 'Inter, sans-serif' }}>
                            {value.toLocaleString()}
                        </Typography>
                    );
                },
            },
        },
    ];

    const smartContractColumns = [
        {
            name: 'organization',
            label: 'Organization',
            options: {
                sort: false,
                // eslint-disable-next-line react/display-name
                customBodyRender: (value: any) => {
                    return (
                        <Typography variant={'caption'} style={{ fontFamily: 'Inter, sans-serif' }}>
                            {value}
                        </Typography>
                    );
                },
            },
        },
        {
            name: 'id',
            label: 'ID',
            options: {
                sort: false,
                // eslint-disable-next-line react/display-name
                customBodyRender: (value: any) => {
                    return (
                        <Typography variant={'caption'} style={{ fontFamily: 'Inter, sans-serif' }}>
                            {value}
                        </Typography>
                    );
                },
            },
        },
        {
            name: 'nbOfTransactions',
            label: 'Number of Transactions',
            options: {
                sort: false,
                // eslint-disable-next-line react/display-name
                customBodyRender: (value: any) => {
                    return (
                        <Typography variant={'caption'} style={{ fontFamily: 'Inter, sans-serif' }}>
                            {value.toLocaleString()}
                        </Typography>
                    );
                },
            },
        },
    ];

    const tableTopicColumns = [
        {
            name: 'organization',
            label: 'Organization',
            options: {
                sort: false,
                // eslint-disable-next-line react/display-name
                customBodyRender: (value: any) => {
                    return (
                        <Typography variant={'caption'} style={{ fontFamily: 'Inter, sans-serif' }}>
                            {value}
                        </Typography>
                    );
                },
            },
        },
        {
            name: 'id',
            label: 'ID',
            options: {
                sort: false,
                // eslint-disable-next-line react/display-name
                customBodyRender: (value: any) => {
                    return (
                        <Typography variant={'caption'} style={{ fontFamily: 'Inter, sans-serif' }}>
                            {value}
                        </Typography>
                    );
                },
            },
        },
        {
            name: 'nbOfTransactions',
            label: 'Number of Transactions',
            options: {
                sort: false,
                // eslint-disable-next-line react/display-name
                customBodyRender: (value: any) => {
                    return (
                        <Typography variant={'caption'} style={{ fontFamily: 'Inter, sans-serif' }}>
                            {value.toLocaleString()}
                        </Typography>
                    );
                },
            },
        },
        {
            name: 'sequenceNumber',
            label: 'Sequence Number',
            options: {
                sort: false,
                // eslint-disable-next-line react/display-name
                customBodyRender: (value: any) => {
                    return (
                        <Typography variant={'caption'} style={{ fontFamily: 'Inter, sans-serif' }}>
                            {value.toLocaleString()}
                        </Typography>
                    );
                },
            },
        },
    ];

    const accountColumns = [
        {
            name: 'organization',
            label: 'Organization',
            options: {
                sort: false,
                // eslint-disable-next-line react/display-name
                customBodyRender: (value: any) => {
                    return (
                        <Typography variant={'caption'} style={{ fontFamily: 'Inter, sans-serif' }}>
                            {value}
                        </Typography>
                    );
                },
            },
        },
        {
            name: 'id',
            label: 'ID',
            options: {
                sort: false,
                // eslint-disable-next-line react/display-name
                customBodyRender: (value: any) => {
                    return (
                        <Typography variant={'caption'} style={{ fontFamily: 'Inter, sans-serif' }}>
                            {value}
                        </Typography>
                    );
                },
            },
        },
        {
            name: 'nbOfTransactions',
            label: 'Number of Transactions',
            options: {
                sort: false,
                // eslint-disable-next-line react/display-name
                customBodyRender: (value: any) => {
                    return (
                        <Typography variant={'caption'} style={{ fontFamily: 'Inter, sans-serif' }}>
                            {value.toLocaleString()}
                        </Typography>
                    );
                },
            },
        },
        {
            name: 'balance',
            label: 'Balance',
            options: {
                sort: false,
                // eslint-disable-next-line react/display-name
                customBodyRender: (value: any) => {
                    const convert = parseFloat(new Hbar(value).toString(HbarUnit.Tinybar)).toLocaleString();
                    return (
                        <Typography variant={'caption'} style={{ fontFamily: 'Inter, sans-serif' }}>
                            {unitValue === 'true' ? `${convert} tℏ` : `${value?.toLocaleString()} ℏ`}
                        </Typography>
                    );
                },
            },
        },
    ];

    const transferColumns = [
        {
            name: 'transactionId',
            label: 'Transaction ID',
            options: {
                sort: false,
                // eslint-disable-next-line react/display-name
                customBodyRender: (value: any) => {
                    return (
                        <Typography
                            variant={'caption'}
                            style={{ fontFamily: 'Inter, sans-serif', overflowWrap: 'anywhere' }}
                        >
                            {value}
                        </Typography>
                    );
                },
            },
        },
        {
            name: 'accounts',
            label: 'Account IDs Involved',
            options: {
                sort: false,
                // eslint-disable-next-line react/display-name
                customBodyRender: (value: any) => {
                    return (
                        <Typography variant={'caption'} style={{ fontFamily: 'Inter, sans-serif' }}>
                            {Array.isArray(value) && value.join(' ; ')}
                        </Typography>
                    );
                },
            },
        },
        {
            name: 'amount',
            label: 'Amount',
            options: {
                sort: false,
                // eslint-disable-next-line react/display-name
                customBodyRender: (value: any) => {
                    const convert = parseFloat(new Hbar(value).toString(HbarUnit.Tinybar)).toLocaleString();
                    return (
                        <Typography
                            variant={'caption'}
                            style={{ fontFamily: 'Inter, sans-serif', overflowWrap: 'anywhere' }}
                        >
                            {unitValue === 'true' ? `${convert} tℏ` : `${value?.toLocaleString()} ℏ`}
                        </Typography>
                    );
                },
            },
        },
    ];

    const nodeColumns = [
        {
            name: 'organization',
            label: 'Organization',
            options: {
                sort: false,
                // eslint-disable-next-line react/display-name
                customBodyRender: (value: any) => {
                    return (
                        <Typography variant={'caption'} style={{ fontFamily: 'Inter, sans-serif' }}>
                            {value}
                        </Typography>
                    );
                },
            },
        },
        {
            name: 'nodeId',
            label: 'Node ID',
            options: {
                sort: false,
                // eslint-disable-next-line react/display-name
                customBodyRender: (value: any) => {
                    return (
                        <Typography variant={'caption'} style={{ fontFamily: 'Inter, sans-serif' }}>
                            {value}
                        </Typography>
                    );
                },
            },
        },
        {
            name: 'nbOfTransactions',
            label: 'Number of Transactions',
            options: {
                sort: false,
                // eslint-disable-next-line react/display-name
                customBodyRender: (value: any) => {
                    if (value) {
                        return (
                            <Typography variant={'caption'} style={{ fontFamily: 'Inter, sans-serif' }}>
                                {value.toLocaleString()}
                            </Typography>
                        );
                    } else {
                        return (
                            <Typography variant={'caption'} style={{ fontFamily: 'Inter, sans-serif' }}>
                                N/A
                            </Typography>
                        );
                    }
                },
            },
        },
        {
            name: 'feeCollected',
            label: 'Fee Collected',
            options: {
                sort: false,
                // eslint-disable-next-line react/display-name
                customBodyRender: (value: any) => {
                    if (value) {
                        const convert = parseFloat(new Hbar(value).toString(HbarUnit.Tinybar)).toLocaleString();
                        return (
                            <Typography variant={'caption'} style={{ fontFamily: 'Inter, sans-serif' }}>
                                {unitValue === 'true' ? `${convert} tℏ` : `${value?.toLocaleString()} ℏ`}
                            </Typography>
                        );
                    } else {
                        return (
                            <Typography variant={'caption'} style={{ fontFamily: 'Inter, sans-serif' }}>
                                N/A
                            </Typography>
                        );
                    }
                },
            },
        },
    ];

    const options = {
        elevation: 0,
        download: false,
        filter: false,
        pagination: false,
        print: false,
        search: false,
        selectableRowsHeader: false,
        selectableRowsHideCheckboxes: true,
        viewColumns: false,
    };

    return (
        <Paper
            elevation={0}
            style={{
                borderRadius: 15,
                padding: 20,
            }}
        >
            <Grid container justifyContent={'center'}>
                <Grid item xs={12}>
                    <Typography
                        variant={'h5'}
                        style={{
                            fontWeight: 'bold',
                            textAlign: 'center',
                            fontFamily: 'Inter, sans-serif',
                            color: theme.palette.mode === 'light' ? '#0d0d0d' : '#FFDB58',
                        }}
                    >
                        Advanced Features Dashboard
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography variant={'caption'} style={{ fontFamily: 'Inter, sans-serif', textAlign: 'center' }}>
                        ** Please note that this is demonstrative data and not reflective of current state
                    </Typography>
                </Grid>
            </Grid>
            <Grid container justifyContent={'center'} spacing={3} style={{ marginTop: 5 }}>
                <Grid item lg={6} xs={12}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Paper
                                elevation={2}
                                style={{
                                    borderRadius: 15,
                                    padding: '15px 10px',
                                    height: 234,
                                    paddingBottom: 25,
                                }}
                            >
                                <Typography
                                    variant={'h5'}
                                    color={'textPrimary'}
                                    style={{ fontWeight: 'bold', fontFamily: 'Inter, sans-serif' }}
                                >
                                    Price Dynamic
                                </Typography>
                                <LineChart
                                    data={priceData}
                                    onDownloadCsv={() => console.log('download')}
                                    chartTitle={''}
                                    primaryText={
                                        theme.palette.mode === 'light' ? theme.palette.text.primary : '#D3D3D3'
                                    }
                                    customClasses={{ fontWeight: 'bold', fontFamily: 'Inter, sans-serif' }}
                                    customVariant={'h6'}
                                    showToolbar={matches}
                                    colourScheme={['#58A3FF']}
                                    chartTheme={theme.palette.mode}
                                    tickAmount={matches ? 12 : 6}
                                    currency={true}
                                />
                            </Paper>
                        </Grid>
                        <Grid item xs={12}>
                            <Paper
                                elevation={2}
                                style={{
                                    borderRadius: 15,
                                    padding: '15px 10px',
                                }}
                            >
                                <Typography
                                    variant={'h5'}
                                    color={'textPrimary'}
                                    style={{ fontWeight: 'bold', fontFamily: 'Inter, sans-serif' }}
                                >
                                    Top Tokens
                                </Typography>
                                <CacheProvider value={muiCache}>
                                    <ThemeProvider theme={tableTheme}>
                                        <Paper elevation={0} style={{ padding: 5, borderRadius: 15 }}>
                                            <MUIDataTable
                                                title={''}
                                                data={token}
                                                columns={tokenColumns}
                                                options={options}
                                            />
                                        </Paper>
                                    </ThemeProvider>
                                </CacheProvider>
                            </Paper>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item lg={5} xs={12}>
                    <Paper
                        elevation={2}
                        style={{
                            borderRadius: 15,
                            padding: '15px 10px',
                            height: matches ? 490 : 575,
                        }}
                    >
                        <Typography
                            variant={'h5'}
                            color={'textPrimary'}
                            style={{ fontWeight: 'bold', fontFamily: 'Inter, sans-serif' }}
                        >
                            Stablecoin
                        </Typography>
                        <Grid container spacing={6}>
                            <Grid item xs={12}>
                                <CacheProvider value={muiCache}>
                                    <ThemeProvider theme={tableTheme}>
                                        <Paper elevation={0} style={{ padding: 5, borderRadius: 15 }}>
                                            <MUIDataTable
                                                title={''}
                                                data={stableData}
                                                columns={tokenColumns}
                                                options={options}
                                            />
                                        </Paper>
                                    </ThemeProvider>
                                </CacheProvider>
                            </Grid>
                            <Grid item xs={12} style={{ height: matchesMed ? 370 : matches ? 290 : 370 }}>
                                <BarChart
                                    data={stableBarData}
                                    onDownloadCsv={() => console.log('download')}
                                    chartTitle={''}
                                    primaryText={
                                        theme.palette.mode === 'light' ? theme.palette.text.primary : '#D3D3D3'
                                    }
                                    customClasses={{ fontWeight: 'bold', fontFamily: 'Inter, sans-serif' }}
                                    customVariant={'h6'}
                                    keys={['value']}
                                    showBarValue={false}
                                    showToolbar={matches}
                                    animateChart={true}
                                    colourScheme={['#58A3FF']}
                                    yFormatter={(value: number) => utils.convertToInternationalCurrencySystem(value)}
                                    chartTheme={theme.palette.mode}
                                    tickAmount={matches ? 12 : 6}
                                />
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
                <Grid item xs={12} lg={11}>
                    <Paper
                        elevation={2}
                        style={{
                            borderRadius: 15,
                            padding: 20,
                        }}
                    >
                        <Typography
                            variant={'h5'}
                            color={'textPrimary'}
                            style={{ fontWeight: 'bold', fontFamily: 'Inter, sans-serif', paddingBottom: 20 }}
                        >
                            Global Network Activity
                        </Typography>
                        <Grid container style={{ height: '100%' }} spacing={6}>
                            <Grid item lg={7} xs={12} style={{ maxHeight: matches ? '100%' : 370, overflow: 'auto' }}>
                                <CacheProvider value={muiCache}>
                                    <ThemeProvider theme={tableTheme}>
                                        <Paper elevation={0} style={{ padding: 5, borderRadius: 15 }}>
                                            <Typography
                                                variant={'h6'}
                                                color={'textPrimary'}
                                                style={{
                                                    fontWeight: 'bold',
                                                    fontFamily: 'Inter, sans-serif',
                                                    marginBottom: 15,
                                                }}
                                            >
                                                Transactions Per Node
                                            </Typography>
                                            <MUIDataTable
                                                title={''}
                                                data={nodeData}
                                                columns={nodeColumns}
                                                options={options}
                                            />
                                        </Paper>
                                    </ThemeProvider>
                                </CacheProvider>
                            </Grid>
                            <Grid item xs={12} lg={5}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} style={{ height: matches ? 230 : 300 }}>
                                        <BarChart
                                            data={accountCreationData}
                                            onDownloadCsv={() => console.log('download')}
                                            chartTitle={'Account Creation'}
                                            primaryText={
                                                theme.palette.mode === 'light' ? theme.palette.text.primary : '#D3D3D3'
                                            }
                                            customClasses={{ fontWeight: 'bold', fontFamily: 'Inter, sans-serif' }}
                                            customVariant={'h6'}
                                            keys={['value']}
                                            showBarValue={false}
                                            showToolbar={matches}
                                            animateChart={true}
                                            colourScheme={['#58A3FF']}
                                            yFormatter={(value: number) =>
                                                utils.convertToInternationalCurrencySystem(value)
                                            }
                                            chartTheme={theme.palette.mode}
                                            tickAmount={matches ? 12 : 6}
                                        />
                                    </Grid>
                                    <Grid item xs={12} style={{ height: matches ? 230 : 300 }}>
                                        <BarChart
                                            data={txVolumeData}
                                            onDownloadCsv={() => console.log('download')}
                                            chartTitle={'Transaction Value'}
                                            primaryText={
                                                theme.palette.mode === 'light' ? theme.palette.text.primary : '#D3D3D3'
                                            }
                                            customClasses={{ fontWeight: 'bold', fontFamily: 'Inter, sans-serif' }}
                                            customVariant={'h6'}
                                            keys={['value']}
                                            showBarValue={false}
                                            showToolbar={matches}
                                            animateChart={true}
                                            colourScheme={['#58A3FF']}
                                            yFormatter={(value: number) =>
                                                utils.convertToInternationalCurrencySystem(value)
                                            }
                                            chartTheme={theme.palette.mode}
                                            tickAmount={matches ? 12 : 6}
                                        />
                                    </Grid>
                                    <Grid item xs={12} style={{ height: matches ? 230 : 300 }}>
                                        <BarChart
                                            data={txValueData}
                                            onDownloadCsv={() => console.log('download')}
                                            chartTitle={'Transaction Value'}
                                            primaryText={
                                                theme.palette.mode === 'light' ? theme.palette.text.primary : '#D3D3D3'
                                            }
                                            customClasses={{ fontWeight: 'bold', fontFamily: 'Inter, sans-serif' }}
                                            customVariant={'h6'}
                                            keys={['value']}
                                            showBarValue={false}
                                            showToolbar={matches}
                                            animateChart={true}
                                            colourScheme={['#58A3FF']}
                                            yFormatter={(value: number) =>
                                                utils.convertToInternationalCurrencySystem(value)
                                            }
                                            chartTheme={theme.palette.mode}
                                            tickAmount={matches ? 12 : 6}
                                        />
                                    </Grid>
                                    <Grid item xs={12} style={{ height: matches ? 230 : 300 }}>
                                        <LineChart
                                            data={txPerSec}
                                            onDownloadCsv={() => console.log('download')}
                                            chartTitle={'Transactions Per Second'}
                                            primaryText={
                                                theme.palette.mode === 'light' ? theme.palette.text.primary : '#D3D3D3'
                                            }
                                            customClasses={{ fontWeight: 'bold', fontFamily: 'Inter, sans-serif' }}
                                            customVariant={'h6'}
                                            showToolbar={matches}
                                            colourScheme={['#58A3FF']}
                                            chartTheme={theme.palette.mode}
                                            tickAmount={matches ? 12 : 6}
                                            yFormatter={(value: number) => `${value.toFixed(2)}`}
                                            tooltipFormatter={(value: any) => `${value}`}
                                            yAxisTickAmount={4}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
                <Grid item lg={5} xs={12}>
                    <Paper
                        elevation={2}
                        style={{
                            borderRadius: 15,
                            padding: '15px 10px',
                        }}
                    >
                        <Typography
                            variant={'h5'}
                            color={'textPrimary'}
                            style={{ fontWeight: 'bold', fontFamily: 'Inter, sans-serif' }}
                        >
                            Top 5 Most Active Smart Contracts
                        </Typography>
                        <CacheProvider value={muiCache}>
                            <ThemeProvider theme={tableTheme}>
                                <Paper elevation={0} style={{ padding: 5, borderRadius: 15 }}>
                                    <MUIDataTable
                                        title={''}
                                        data={smartContract}
                                        columns={smartContractColumns}
                                        options={options}
                                    />
                                </Paper>
                            </ThemeProvider>
                        </CacheProvider>
                    </Paper>
                </Grid>
                <Grid item lg={6} xs={12}>
                    <Paper
                        elevation={2}
                        style={{
                            borderRadius: 15,
                            padding: '15px 10px',
                        }}
                    >
                        <Typography
                            variant={'h5'}
                            color={'textPrimary'}
                            style={{ fontWeight: 'bold', fontFamily: 'Inter, sans-serif' }}
                        >
                            Top 5 Most Active Topics
                        </Typography>
                        <CacheProvider value={muiCache}>
                            <ThemeProvider theme={tableTheme}>
                                <Paper elevation={0} style={{ padding: 5, borderRadius: 15 }}>
                                    <MUIDataTable
                                        title={''}
                                        data={topic}
                                        columns={tableTopicColumns}
                                        options={options}
                                    />
                                </Paper>
                            </ThemeProvider>
                        </CacheProvider>
                    </Paper>
                </Grid>
                <Grid item lg={5} xs={12}>
                    <Paper
                        elevation={2}
                        style={{
                            borderRadius: 15,
                            padding: '15px 10px',
                        }}
                    >
                        <Typography
                            variant={'h5'}
                            color={'textPrimary'}
                            style={{ fontWeight: 'bold', fontFamily: 'Inter, sans-serif' }}
                        >
                            Top 5 Most Active Accounts
                        </Typography>
                        <CacheProvider value={muiCache}>
                            <ThemeProvider theme={tableTheme}>
                                <Paper elevation={0} style={{ padding: 5, borderRadius: 15 }}>
                                    <MUIDataTable
                                        title={''}
                                        data={accountData}
                                        columns={accountColumns}
                                        options={options}
                                    />
                                </Paper>
                            </ThemeProvider>
                        </CacheProvider>
                    </Paper>
                </Grid>
                <Grid item lg={6} xs={12}>
                    <Paper
                        elevation={2}
                        style={{
                            borderRadius: 15,
                            padding: '15px 10px',
                        }}
                    >
                        <Typography
                            variant={'h5'}
                            color={'textPrimary'}
                            style={{ fontWeight: 'bold', fontFamily: 'Inter, sans-serif' }}
                        >
                            Top 5 Biggest Crypto Transfers
                        </Typography>
                        <CacheProvider value={muiCache}>
                            <ThemeProvider theme={tableTheme}>
                                <Paper elevation={0} style={{ padding: 5, borderRadius: 15 }}>
                                    <MUIDataTable
                                        title={''}
                                        data={transfers}
                                        columns={transferColumns}
                                        options={options}
                                    />
                                </Paper>
                            </ThemeProvider>
                        </CacheProvider>
                    </Paper>
                </Grid>
            </Grid>
        </Paper>
    );
};

export default DemoDashboard;
